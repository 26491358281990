.infoBlock {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scrollbar-color: white;
    margin-right: 20px;
    user-select: none;
    /* scroll-behavior: smooth;
    -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
    /* scrollbar-width: none;  */ 
}

.infoBlock:active {
    cursor: grabbing; /* Меняем курсор на "захват" при нажатии */
}
.wardeobeBlock{
    height: calc(60dvh + 10px);
    width: 100%;
    opacity: 1;
    width: 100vw;
    bottom: 110px;
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: row;
}
.sideWiderobe{
    height: 100%;
    background-color: #d6d6d5;
}
.sideWiderobe svg{
    height: 35px;
    width: 35px;
}
.sideWiderobe img{
    height: 40px;
    width: 40px;
}
.person img{
    user-select: none;
}
.itemCustom{
    margin-left: 5px;
    /* background-color: #24242e; */
    border-right: #afafae solid 1px;    
    box-shadow: inset rgb(211, 208, 196) 0px 0px 20px -12px; 
}
.itemCustomActive{
    margin-left: 5px;
    /* background-color: #24242e; */
    border-right: #bd7320 solid 1px; 
    box-shadow: inset rgb(194, 167, 46) 0px 0px 50px -12px; 
}
.infoBlock{
    /* background-color: #24242e; */
    width: 100%;
    padding-right: 6rem;
}
.infoBlock::-webkit-scrollbar {
    height: 5px;
    width: 250px;
}

.infoBlock::-webkit-scrollbar-track {
    background: rgb(196, 16, 157);
    height: 3px;
}


::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #aeafaf;
    border-radius: 6px;
}