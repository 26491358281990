.previewContainer {
  position: relative;
  height: 12rem;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewContainer:hover {
  transform: scale(1.02);
}



.thumbnail {
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

/* .playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
  z-index: 2;
} */

/* .playButton svg {
  font-size: 48px;
  color: rgba(255, 255, 255, 0.9);
} */

.loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(
    117deg,
    rgba(190, 29, 195, 0.3) 34%,
    rgba(231, 13, 230, 0.3) 68%
  );
}

/* Добавляем оверлей при наведении */
/* .previewContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  pointer-events: none;
} */
