.category {
  padding-top: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 3px solid #a10af8;
  box-shadow: #e40af8 0px -4px 5px;
  font-family: "Segoe UI";
}
.categoryMargin {
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 21px;
  font-weight: 700;
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.categoryBtn {
  margin: 0px 10px;
  padding: 3px 0px 6px 0px;
  border-radius: 13px;
  background-color: #d0d1d3;
  border: 2px solid #030570;
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
  box-shadow: inset 0 -6px 2px -2px rgb(102, 102, 102);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d622a0;
}

.categoryBtnActive {
  margin: 0px 10px;
  background: linear-gradient(
    117deg,
    rgba(190, 29, 195, 1) 34%,
    rgba(231, 13, 230, 1) 68%
  );
  padding: 3px 0px 6px 0px;
  border: 2px solid #202020;
  display: flex;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  color: #eff4fa;
  box-shadow: inset 0 -6px 2px -2px rgb(102, 7, 102);
}
.updatesListBlock {
  padding: 0px 10px 100px 10px;
  overflow-y: auto;
  height: calc(88dvh - 180px); /* учитываем высоту хедера и категорий */
}
.updateBlock {
  display: grid;
  grid-template-columns: 3fr 2fr;
  background: linear-gradient(
    90deg,
    rgba(91, 10, 91, 0.9) 0%,
    rgba(140, 3, 140, 0.8) 100%
  );
  min-height: 5rem;
  height: auto;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 6px;
  margin-top: 7px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 15px rgba(231, 13, 230, 0.3),
    0 2px 4px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(231, 13, 230, 0.2);
}

.updateBlock::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
}

.topLeft {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 3px;
}

.topLeft img {
  height: 3.5rem;
  width: 3.5rem;
  object-fit: cover;
  border: 2px solid rgba(231, 13, 230, 0.3);
  border-radius: 8px;
  padding: 2px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(231, 13, 230, 0.3);
}

.percent {
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgba(0, 0, 0, 0.4);
  padding: 2px 6px;
  border-radius: 15px;
  font-size: 14px;
  box-shadow: inset 0 0 5px rgba(231, 13, 230, 0.2);
}

.updateName {
  height: 1.2rem;
  overflow: hidden;
  font-size: 14px;
  padding: 6px 5px;
  margin-bottom: 7px;
  font-style: italic;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  margin-top: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: inset 0 0 5px rgba(231, 13, 230, 0.2);
}

.bonus {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 3px;
  position: relative;
}

.bonus::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10%;
  bottom: 10%;
  width: 1px;
  background: linear-gradient(
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
}

.bonusNum,
.bonusPay {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  padding: 3px 6px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  box-shadow: inset 0 0 5px rgba(231, 13, 230, 0.2);
}

.bonusNum {
  color: #7fff00;
}

.bonusPay {
  color: #cd5c5c;
}

.bonusNum img,
.bonusPay img {
  height: 25px;
  width: 25px;
  filter: drop-shadow(0 0 3px rgba(231, 13, 230, 0.4));
}

/* Обновленные стили для разных статусов */
.updateBlock[data-status="locked"] {
  opacity: 0.7;
  filter: grayscale(0.5);
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.4);
}

.updateBlock[data-status="locked"]::after {
  content: "🔒";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.updateBlock[data-status="max_level_reached"] {
  background: linear-gradient(
    90deg,
    rgba(79, 79, 79, 0.9) 0%,
    rgba(112, 112, 112, 0.8) 100%
  );
  box-shadow: inset 0 0 15px rgba(128, 128, 128, 0.3),
    0 2px 4px rgba(0, 0, 0, 0.4);
}

/* Анимация для доступных апгрейдов */
.updateBlock[data-status="available"]:hover {
  transform: translateY(-2px);
  box-shadow: inset 0 0 20px rgba(231, 13, 230, 0.4),
    0 4px 8px rgba(0, 0, 0, 0.5);
}

.updateBlockD img {
  height: 50px;
}

.updatesHeader {
  margin-bottom: 20px;
  margin-top: 20px;
  color: #e7e7e7;
  font-size: 19px;
  font-weight: 800;
}

/* Обновили стили для Progress компонента */
:global(.ant-progress-circle) {
  font-size: 12px !important;
}
