.block {
  background: linear-gradient(
    180deg,
    rgba(48, 16, 255, 0.2) 0%,
    rgba(100, 15, 158, 0.2) 100%
  );
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.block:hover {
  transform: translateY(-2px);
}
.images {
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;
}
.images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.typeSvg {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.typeSvg img,
.typeSvg svg {
  width: 20px;
  height: 20px;
}
.text {
  padding: 10px 0;
}
.name {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
}
.get {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ffd700;
  font-weight: 600;
}
.get img {
  width: 20px;
  height: 20px;
}
.reward_collected {
  color: #66bd6d;
}
.reward_available {
  color: #ffd700;
  animation: pulse 1.5s infinite;
}
.active {
  color: #ff8c00;
}
.not_started {
  color: #888;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
