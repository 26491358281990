.friendsPage {
  padding: 16px;
  min-height: calc(100vh - 60px);
  background: linear-gradient(
    340deg,
    rgba(97, 50, 133, 1) 16%,
    rgba(23, 48, 119, 1) 43%,
    rgba(25, 25, 85, 1) 76%
  );
}

.title {
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-shadow: 0 0 10px rgba(231, 13, 230, 0.5);
}

.section {
  background: rgba(147, 51, 234, 0.35);
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid rgba(231, 13, 230, 0.2);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.sectionTitle {
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  padding: 8px;
  background: rgba(231, 13, 230, 0.15);
  border-radius: 8px;
}

.friend {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid rgba(231, 13, 230, 0.3);
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 19px;
  font-weight: 600;
}

.friend:last-child {
  border-bottom: none;
}

.friend:hover {
  background: rgba(231, 13, 230, 0.15);
  transform: translateX(5px);
}

.friendAvatar {
  width: 40px;
  height: 40px;
  border: 2px solid rgba(231, 13, 230, 0.5);
  border-radius: 10px;
  margin-right: 12px;
}

.friendInfo {
  flex: 1;
  color: white;
}

.friendDuration {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.request {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid rgba(231, 13, 230, 0.3);
}

.request:last-child {
  border-bottom: none;
}

.requestButtons {
  display: flex;
  gap: 8px;
}

.acceptButton,
.rejectButton {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.acceptButton {
  background: rgba(22, 163, 74, 0.8);
  color: white;
}

.rejectButton {
  background: rgba(220, 38, 38, 0.8);
  color: white;
}

.acceptButton:hover {
  background: rgba(22, 163, 74, 1);
  transform: scale(1.05);
}

.rejectButton:hover {
  background: rgba(220, 38, 38, 1);
  transform: scale(1.05);
}

.actionButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 20px;
}

.actionButton {
  padding: 12px;
  border-radius: 12px;
  border: none;
  background: linear-gradient(
    117deg,
    rgba(190, 29, 195, 1) 34%,
    rgba(231, 13, 230, 1) 68%
  );
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.actionButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(231, 13, 230, 0.3);
}

.copySuccess {
  text-align: center;
  color: #4ade80;
  margin-top: 8px;
  font-size: 14px;
}

.closeButton {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: rgba(255, 255, 255, 0.2);
}
