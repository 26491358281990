.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.videoContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.video {
  width: 100%;
  max-height: calc(100vh - 150px); /* оставляем место для кнопок */
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(231, 13, 230, 0.3);
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  backdrop-filter: blur(5px);
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(
    117deg,
    rgba(190, 29, 195, 1) 34%,
    rgba(231, 13, 230, 1) 68%
  );
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(231, 13, 230, 0.4);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .popup {
    padding: 10px;
  }

  .buttonsContainer {
    padding: 8px;
    gap: 10px;
  }

  .button {
    padding: 8px 15px;
    font-size: 14px;
  }
}
