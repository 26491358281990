.nav {
  position: fixed;
  bottom: 0px;
  /* background: linear-gradient(
    55deg,
    rgba(142, 0, 142, 1) 10%,
    rgba(214, 0, 214, 1) 15%,
    rgba(142, 0, 142, 1) 20%,
    rgba(142, 0, 142, 1) 30%,
    rgba(214, 0, 214, 1) 35%,
    rgba(142, 0, 142, 1) 40%,
    rgba(142, 0, 142, 1) 50%,
    rgba(214, 0, 214, 1) 55%,
    rgba(142, 0, 142, 1) 60%,
    rgba(142, 0, 142, 1) 70%,
    rgba(214, 0, 214, 1) 75%,
    rgba(142, 0, 142, 1) 80%,
    rgba(142, 0, 142, 1) 85%
  ); */
  background-color: #46054e;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: #f0e9ef;
  height: 5.5rem;
  border-top: #f0e9ef 2px solid;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.icon {
  /* background: linear-gradient(180deg, rgb(110, 13, 54) 0%, rgb(22, 22, 61) 93%); */
  /* background: url("../../assets/Buttons/scene.png"); */
  width: 17dvw;
  /* background-size: cover; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 4.5rem;
  color: #f0e9ef;
  box-shadow: inset rgb(12, 1, 14) 0px 0px 24px -12px;
  border-radius: 8px;
  border: 2px solid #c1d9ee;
  background: linear-gradient(225deg, #a0157d, #46054e);
  box-shadow: -5px 5px 10px #660d72, 5px -5px 16px #46054e;
  /* border-right: #24242e 1px solid; */
}
.icon a {
  text-decoration: none;
  color: #f0e9ef;
}
.iconA {
  /* background: linear-gradient(
    180deg,
    rgb(238, 20, 191) 0%,
    rgb(22, 22, 61) 93%
  ); */
  /* background: url("../../assets/Buttons/scene.png"); */
  background-size: cover;
  width: 17dvw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 4.5rem;
  color: white;
  border-radius: 8px;
  border: 2px solid #ffdc18;
  background: linear-gradient(225deg, #a0157d, #46054e);
  box-shadow: -5px 5px 10px #660d72, 5px -5px 16px #46054e;
}
.iconA a {
  text-decoration: none;
  color: #f0e9ef;
}
.name {
  /* padding-top: 5px; */
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* font-size: 2.8vw; */
  /* font-size: 55%; */
  font-size: clamp(0.7rem, 2vw, 2.5rem);
  text-decoration: none !important;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.icon a,
.iconA a,
.nav a {
  text-decoration: none;
  color: inherit; /* Наследовать цвет от родителя */
}
.name a {
  text-decoration: none;
}
.icon svg,
.icon img,
.iconA svg,
.iconA img {
  height: 70%;
  width: 75%;
  max-width: 55px;
  max-height: 55px;
}

.icon svg {
  height: 35px;
  width: 35px;
}
.iconA svg {
  height: 35px;
  width: 35px;
}
.nav a {
  color: #4d4d4d;
  font-size: 20px;
}
.newLanguages {
  position: relative;
  display: inline-block;
}

.newLanguages select {
  width: 100px;
  font-size: 20px;
  height: 52px;
  text-align: center;
  border-radius: 4px;
}

@media screen and (max-width: 880px) {
  .left article {
    max-width: 250px;
  }
}
@media screen and (max-width: 768px) {
  .top {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
  .left {
    width: 100%;
    justify-content: center;
  }
  .right {
    justify-content: center;
  }
}
