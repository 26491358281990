.container {
  padding: 16px;
  background: linear-gradient(
    340deg,
    rgba(97, 50, 133, 1) 16%,
    rgba(23, 48, 119, 1) 43%,
    rgba(25, 25, 85, 1) 76%
  );
  min-height: calc(100vh - 60px); /* Учитываем нижнюю навигацию */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sectionTitle {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 12px;
  background: rgba(231, 13, 230, 0.15);
  border-radius: 8px;
  margin-bottom: 8px;
  text-shadow: 0 0 10px rgba(231, 13, 230, 0.5);
}

.favoriteSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  border: 1px solid rgba(231, 13, 230, 0.2);
}

.favoriteVideo {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.favoriteVideo :global(.previewContainer) {
  aspect-ratio: 2/1;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(231, 13, 230, 0.3);
}

.recentSection {
  padding: 12px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  border: 1px solid rgba(231, 13, 230, 0.2);
}

.recentVideos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4px;
  margin-top: 8px;
}

.recentVideo :global(.previewContainer) {
  aspect-ratio: 2/1;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(231, 13, 230, 0.3);
}

.favoriteButton {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.favoriteButton:not(:disabled):hover {
  background: rgba(255, 255, 255, 0.2);
}

.favoriteActive {
  color: #ff4d4f;
  cursor: not-allowed;
}

.favoriteActive:hover {
  background: rgba(255, 255, 255, 0.1);
}
