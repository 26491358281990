.blockDay {
  background-color: #2b2f33;
  min-width: 30px;
  min-height: 80px;
}

.dailyList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-right: 15px;
  margin-left: 20px;
}

.active {
  background-color: rgb(73, 73, 71);
  width: 65px;
  min-height: 110px;
  border-radius: 10px;
  box-shadow: inset rgb(68, 70, 82) 0px 0px 22px -12px;
  color: #8f8e8e;
  border: #66bd6d 2px solid;
  padding: 5px;
}

.inactive {
  background-color: rgb(73, 73, 71);
  width: 65px;
  min-height: 110px;
  border-radius: 10px;
  box-shadow: inset rgb(68, 70, 82) 0px 0px 22px -12px;
  color: #b6b6b6;
  border: #000000 2px solid;
  padding: 5px;
}

.upPageBlock {
  background: linear-gradient(
    90deg,
    rgba(91, 10, 91, 0.9) 0%,
    rgba(140, 3, 140, 0.8) 50%,
    rgba(91, 10, 91, 0.9) 100%
  );
  color: #f5f5f5;
  padding: 10px 20px;
  height: 12dvh;
  position: relative;
  z-index: 2;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.statsContainer {
  max-width: 600px;
  margin: 0 auto;
}

.statsRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.statsItem {
  flex: 1;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.imgBlock {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 4px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.imgBlock img {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}

.income {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #a8a8a8;
}

.income img {
  height: 24px;
  width: 24px;
  margin-right: 6px;
}

.user {
  margin-top: 8px;
  font-size: 0.9rem;
  color: #d0d0d0;
  text-align: left;
}

.questListCollapse {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.questList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 20px 20px 10px 20px;
}
