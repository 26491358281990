.filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
}
.filter .categories span {
  cursor: pointer;
  display: inline-block;
  border-radius: 15px;
  color: white;
  text-decoration-line: none;
  padding: 4px 9px;
}

.filter .categories .edu {
  background-color: #ba8f95;
}

.filter .categories .science {
  background-color: #d7d9b1;
}

.filter .categories .teen {
  background-color: #cfbcdf;
}

.filter .categories .project {
  background-color: #89669d;
}

.filter .search input {
  padding: 2px 4px;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .filter .categories {
    flex-direction: row;
    justify-content: flex-start;
  }

  .filter {
    align-items: flex-start;
  }
}

.container {
  background: linear-gradient(
    340deg,
    rgba(97, 50, 133, 1) 16%,
    rgba(23, 48, 119, 1) 43%,
    rgba(25, 25, 85, 1) 76%
  );
  height: calc(100dvh - 60px);
  overflow: scroll;
  padding-bottom: 70px;
}

.item {
  margin: 8px 15px;
  display: flex;
  flex-direction: row;
  background: linear-gradient(
    90deg,
    rgba(91, 10, 91, 0.9) 0%,
    rgba(140, 3, 140, 0.8) 100%
  );
  border-radius: 12px;
  padding: 8px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 15px rgba(231, 13, 230, 0.3),
    0 2px 4px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(231, 13, 230, 0.2);
  cursor: pointer;
}

.item:hover {
  transform: translateY(-2px);
  box-shadow: inset 0 0 20px rgba(231, 13, 230, 0.4),
    0 4px 8px rgba(0, 0, 0, 0.5);
}

.item img {
  height:100px;
  width: 100px;
  object-fit: contain;
  border: 2px solid rgba(231, 13, 230, 0.3);
  border-radius: 8px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(231, 13, 230, 0.3);
}

.itemInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
  flex: 1;
}

.name {
  font-size: 18px;
  color: white;
  font-weight: 700;
  margin-bottom: 5px;
  text-shadow: 0 0 10px rgba(231, 13, 230, 0.5);
}

.price,
.bonus {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  box-shadow: inset 0 0 5px rgba(231, 13, 230, 0.2);
  margin: 2px 0;
}

.price {
  color: #ffd700;
}

.bonus {
  color: #7fff00;
}

.price img,
.bonus img {
  height: 20px !important;
  width: 20px !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.category {
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgba(25, 25, 85, 0.95);
  padding-top: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 3px solid #a10af8;
  box-shadow: #e40af8 0px -4px 5px;
}

.categoryMargin {
  margin: 0 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding-bottom: 10px;
}

.categoryBtn {
  margin: 0px 10px;
  padding: 3px 0px 6px 0px;
  border-radius: 13px;
  background-color: #d0d1d3;
  border: 2px solid #030570;
  box-shadow: inset 0 -6px 2px -2px rgb(102, 102, 102);
  font-weight: 700;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d622a0;
}

.categoryBtnActive {
  margin: 0px 10px;
  background: linear-gradient(
    117deg,
    rgba(190, 29, 195, 1) 34%,
    rgba(231, 13, 230, 1) 68%
  );
  padding: 3px 0px 6px 0px;
  border: 2px solid #202020;
  display: flex;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 21px;
  color: #eff4fa;
  box-shadow: inset 0 -6px 2px -2px rgb(102, 7, 102);
}

.content {
  padding-top: 10px;
  overflow-y: auto;
}

/* Добавляем стили для скроллбара */
.container::-webkit-scrollbar,
.content::-webkit-scrollbar {
  width: 6px;
}

.container::-webkit-scrollbar-track,
.content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.container::-webkit-scrollbar-thumb,
.content::-webkit-scrollbar-thumb {
  background: rgba(231, 13, 230, 0.5);
  border-radius: 3px;
}
