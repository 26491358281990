.header {
  width: 100%;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  padding-top: 20px;
}

.header a {
  text-decoration-line: none;
  font-family: "Jost", sans-serif;
}
.header button {
  font-family: "Jost", sans-serif;
  transition: color 0.3s;
}
.top {
  display: flex;
  justify-content: space-between;
  width: calc(90% * 0.95);
  max-width: 1640px;
  margin: 0 auto;
}
.bottom {
  margin: 0 auto;
}

.left {
  display: flex;
  column-gap: 16px;
  align-items: center;
}
.left a {
  width: 211px;
}
.left a img {
  width: 50%;
}
.left article {
  max-width: 335px;
  font-size: 20px;

  line-height: 1.1;
}

.right {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.score {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
  /* gap: 20px; */
  overflow: hidden;
}

.counter {
  width: 10rem;
  overflow: hidden;
  padding: 8px 3px;
  background-color: #24242e;
  color: rgb(238, 238, 238);
  /* margin-top: 10px; */
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border: 2px solid #b4b0af;
  /* box-shadow: #bf3d15 0px 0px 5px ; */
}
.counter svg{
  height: 30px;
  width: 30px;
  /* color: #bf3d15; */
  /* background-color: #bf3d15; */
}
.counter:hover{
    box-shadow: #bf3d15 0px 0px 15px;
}
.moving {
  animation: moveLeft 5s linear infinite;
}
.scoreContainer {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
}
@keyframes moveLeft {
  0% {
    transform: translateX(145%);
  }
  100% {
    transform: translateX(-200%);
  }
}

.scoreContainer {
  display: flex;
  align-items: center;
}


.score {
  position: relative;
  z-index: 1;
  font-weight: bold;
}

.scoreAnimationContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  font-size: 24px;
}

.scoreAnimation {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  font-weight: bold;
  color: rgb(178, 13, 184);
  animation: riseAndFade 1s ease-out forwards;
}

@keyframes riseAndFade {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -40px);
  }
}

