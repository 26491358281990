.main {
  width: 100%;
  background-image: url("../../assets/backMain3.png");
  background-size: cover;
  background-position: center;
  height: calc(100dvh - 5.4rem);
  overflow: hidden;
}
.mainWiderobe {
  width: 100%;
  background-image: url("../../assets/backward1.png");
  background-position: center;
  background-size: cover;
  height: calc(100dvh - 5.4rem);
  overflow: hidden;
}

.person {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}
.person img {
  position: absolute;
  height: 60dvh;
  bottom: 120px;
}
.mainBtn {
  color: white;
  height: 60px;
  font-size: 23px;
  width: 70vw;
  background: linear-gradient(120deg, darkmagenta, crimson, orange);
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.5s;
  border: 0;
  padding: 5px 10px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}
.mainBtn:hover {
  background-position: 0 0;
}
.leftIcons {
  position: absolute;
  left: 15px;
  z-index: 3;
  top: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.leftIcons img {
  height: 50px;
  width: 50px;
  margin-bottom: 50px;
  border-radius: 12px;
  border: 2px solid #dcdcdf;
  transition: all 0.3s ease;
}
.leftIcons img:hover {
  transform: scale(1.1);
}
.leftIcons img[style*="not-allowed"]:hover {
  transform: none;
}
.timer {
  background-color: #24242e;
  position: absolute;
  border-radius: 6px;
  top: 40px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  padding: 2px 4px;
  text-align: center;
  min-height: 20px;
}
.leftIcons svg {
  height: 40px;
  width: 40px;
  margin-bottom: 50px;
}
.rightIcons {
  position: absolute;
  right: 15px;
  z-index: 3;
  top: 35vh;
  display: flex;
  flex-direction: column;
}
.rightIcons svg {
  height: 50px;
  width: 50px;
  color: orange;
  margin-bottom: 50px;
}
.rightIcons img {
  height: 50px;
  width: 50px;
  margin-bottom: 50px;
  border-radius: 12px;
  border: 2px solid #dcdcdf;
}
