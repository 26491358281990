.page {
  min-height: calc(80dvh - 60px);
}
.questsBlock {
  padding-bottom: 8rem;
  overflow: scroll !important;
  height: calc(70dvh - 5.5rem - 72px);
  padding-left: 5px;
  padding-right: 5px;
}
.category {
  padding-top: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 3px solid #a10af8;
  box-shadow: #e40af8 0px -4px 5px;
  font-family: "Segoe UI";
}
.categoryMargin {
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 22px;
  font-weight: 700;
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.categoryBtn {
  margin: 0px 10px;
  padding: 5px 0px 10px 0px;
  border-radius: 13px;
  background-color: #d0d1d3;
  border: 2px solid #030570;
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
  box-shadow: inset 0 -6px 2px -2px rgb(110, 110, 110);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d622a0;
}

.categoryBtnActive {
  margin: 0px 10px;
  background: linear-gradient(
    117deg,
    rgba(190, 29, 195, 1) 34%,
    rgba(231, 13, 230, 1) 68%
  );
  padding: 5px 0px 10px 0px;
  border: 2px solid #202020;
  display: flex;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  color: #eff4fa;
  box-shadow: inset 0 -6px 2px -2px rgb(114, 8, 114);
}

.blockDay {
  background-color: #2b2f33;
  min-width: 30px;
  min-height: 80px;
}

.dailyList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-right: 15px;
  margin-left: 15px;
  padding-bottom: 70px;
}

.active {
  background-color: rgb(130, 12, 134);
  width: 4.5rem;
  height: 8rem;
  border-radius: 10px;
  box-shadow: inset 0 -6px 2px -2px rgb(114, 8, 114);
  color: #ece7e7;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 20px;
  font-weight: 700;
  border: #66bd6d 2px solid;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.active img,
.inactive img {
  height: 50px;
  width: 50px;
  border-bottom: #370852 1px solid;
  padding-bottom: 10px;
  padding-top: 5px;
  /* margin-right: 20px; */
}
.claimable {
  border: #ffd700 2px solid; /* золотая рамка для доступного бонуса */
  animation: pulse 1.5s infinite; /* добавляем пульсацию */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.inactive {
  background-color: rgb(130, 12, 134);
  opacity: 0.7; /* делаем неактивные блоки немного прозрачными */
  width: 4.5rem;
  height: 8rem;
  border-radius: 10px;
  box-shadow: inset rgb(68, 70, 82) 0px 0px 22px -12px;
  color: #ece7e7;
  border: #000000 2px solid;
  padding: 5px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bonus {
  color: #10a51d;
}
.upPageBlock {
  color: #f5f5f5;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.upPageBlock img {
  height: 50px;
  width: 50px;
  margin-right: 20px;
}
.imgBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 2rem;
  font-weight: 700;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.user {
  margin-top: 10px;
  margin-left: 8px;
}
.stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.income {
  font-size: 20px;
  font-weight: 600;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.questListCollapse {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.questList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin: 20px 20px 10px 20px;
}

.container {
  height: calc(70dvh);
  overflow: scroll;
}

.questsContent {
  height: calc(88dvh - 180px);
  overflow-y: auto;
  padding: 0 10px 100px 10px;
}
