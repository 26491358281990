body {
    display: flex;
    flex-direction: column;
    font-family: 'Jost', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    font-size: 16px;
    width: 100% !important;
}

footer {
    margin-top: auto;
}

.root{
    margin: 0;
}

html {overflow-y: scroll;}