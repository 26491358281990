.pageContent {
  height: calc(88dvh - 60px); /* 100dvh - header(12dvh) - footer(60px) */
  position: relative;
  overflow-y: auto;
}
.page {
  min-height: calc(100dvh - 60px);
  width: 100vw;
  background: linear-gradient(
    340deg,
    rgba(97, 50, 133, 1) 16%,
    rgba(23, 48, 119, 1) 43%,
    rgba(25, 25, 85, 1) 76%
  );
  position: relative;
  display: flex;
  flex-direction: column;
}
.category {
  position: sticky;
  top: 0;
  padding-top: 20px;
  margin-top: -20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 3px solid #a10af8;
  box-shadow: #e40af8 0px -4px 5px;
  background: inherit;
  z-index: 1;
  overflow: hidden;
  font-family: "Segoe UI";
}
.category::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: -1;
}
.categoryMargin {
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 22px;
  font-weight: 700;
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.categoryBtn {
  margin: 0px 10px;
  padding: 5px 0px 10px 0px;
  border-radius: 13px;
  background-color: #d0d1d3;
  border: 2px solid #030570;
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
  box-shadow: inset 0 -6px 2px -2px rgb(110, 110, 110);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d622a0;
}

.categoryBtnActive {
  margin: 0px 10px;
  background: linear-gradient(
    117deg,
    rgba(190, 29, 195, 1) 34%,
    rgba(231, 13, 230, 1) 68%
  );
  padding: 5px 0px 10px 0px;
  border: 2px solid #202020;
  display: flex;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  color: #eff4fa;
  box-shadow: inset 0 -6px 2px -2px rgb(114, 8, 114);
}
