.top {
  height: calc(100vh - 260px);
  overflow: scroll;
  padding: 0 15px;
}

.leaderboard {
  padding: 15px;
  background: linear-gradient(
    180deg,
    rgba(48, 16, 255, 0.2) 0%,
    rgba(100, 15, 158, 0.2) 100%
  );
  border-radius: 15px;
  margin-bottom: 20px;
}
.leaderboard h2 {
  color: #fff;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.categories {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 0 10px;
}
.categoryBtn {
  padding: 8px 0;
  border-radius: 13px;
  background-color: #d0d1d3;
  border: 2px solid #030570;
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
  box-shadow: inset 0 -6px 2px -2px rgb(102, 102, 102);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #951cb9;
  font-weight: 700;
  font-size: 1.1em;
}

.categoryBtnActive {
  padding: 8px 0;
  background: linear-gradient(
    117deg,
    rgba(190, 29, 195, 1) 34%,
    rgba(231, 13, 230, 1) 68%
  );
  border: 2px solid #202020;
  display: flex;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  color: #eff4fa;
  box-shadow: inset 0 -6px 2px -2px rgb(102, 7, 102);
  font-weight: 700;
  font-size: 1.1em;
}

.description {
  color: #e9e9e9;
  font-size: 1.1em;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
}
.description img {
  height: 24px !important;
  width: 24px !important;
}
.title {
  font-size: 1.2em;
  color: white;
  font-weight: 800;
  font-family: "Segoe UI", sans-serif;
  margin-bottom: 5px;
}

/* Стили для элементов списка лидеров */
.leaderItem {
  background: linear-gradient(
    90deg,
    rgba(97, 50, 133, 0.3) 0%,
    rgba(23, 48, 119, 0.3) 100%
  );
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.leaderItem:hover {
  transform: translateX(5px);
  background-color: rgba(115, 17, 145, 0.2);
}

/* Стили для текущего пользователя */
.currentUser {
  margin-top: 20px;
  padding: 15px;
  background: linear-gradient(
    90deg,
    rgba(231, 13, 230, 0.3) 0%,
    rgba(190, 29, 195, 0.3) 100%
  );
  border-radius: 15px;
  border: 2px solid rgba(231, 13, 230, 0.5);
}

.currentUser h3 {
  color: #fff;
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}

/* Стили для рангов */
.rank {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 1.2em;
  margin-right: 10px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.rank1 {
  background: linear-gradient(45deg, #ffd700, #ffa500);
}
.rank2 {
  background: linear-gradient(45deg, #c0c0c0, #a9a9a9);
}
.rank3 {
  background: linear-gradient(45deg, #cd7f32, #8b4513);
}

.closeButton {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: rgba(255, 255, 255, 0.2);
}
