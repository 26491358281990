.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: linear-gradient(
    340deg,
    rgba(97, 50, 133, 1) 16%,
    rgba(23, 48, 119, 1) 43%,
    rgba(25, 25, 85, 1) 76%
  );
  padding: 20px;
  border-radius: 12px;
  min-width: 300px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
  color: white;
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.2em;
}

.message {
  color: #e0e0e0;
  text-align: center;
  margin-bottom: 20px;
}

.priceContainer,
.bonusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  gap: 10px;
}

.price,
.bonus {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
}

.price img,
.bonus img {
  width: 20px;
  height: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.confirmButton,
.cancelButton {
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  min-width: 100px;
}

.confirmButton {
  background-color: #4caf50;
  color: white;
}

.cancelButton {
  background-color: #f44336;
  color: white;
}

.priceLabel,
.bonusLabel {
  color: #e0e0e0;
}

.bonusDescription {
  color: #e0e0e0;
  font-size: 0.9em;
  margin-left: 5px;
}
