/* Стили для Collapse */
.ant-collapse {
  background: none !important;
  border: none !important;
}

.ant-collapse-item {
  background: rgba(147, 51, 234, 0.35) !important;
  border-radius: 16px !important;
  margin-bottom: 16px !important;
  border: 1px solid rgba(231, 13, 230, 0.2) !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2) !important;
}

.ant-collapse-header {
  color: white !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 12px 16px !important;
  background: rgba(231, 13, 230, 0.15) !important;
  border-radius: 15px 15px 0 0 !important;
}

.ant-collapse-content {
  background: none !important;
  border-top: 1px solid rgba(231, 13, 230, 0.2) !important;
}

/* Стили для Spin */
.ant-spin-dot-item {
  background-color: #e70de6 !important;
}

.ant-spin-text {
  color: white !important;
}
